<template>
    <div>
        <h1>
            {{ $store.state.language.layout.bread_crumbs.customer_register_list }}
        </h1>
        <div style="width: 100%;overflow: auto;">
            <table class="table" style="width: 100%;">
                <thead>
                    <tr>
                        <th style="width: 3em;">#</th>
                        <th style="width: 8em;">
                            {{ $store.state.language.agent.list_column.ctime }}
                        </th>
                        <th style="width: 15em;">
                            {{ $store.state.language.agent.list_column.company_name }}
                        </th>
                        <th style="width: 15em;">
                            {{ $store.state.language.agent.list_column.required_model }}
                        </th>
                        <th>
                            {{ $store.state.language.agent.list_column.status }}
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(d, dk) in dataList" :key="d.id">
                    <tr @click="d.show_detail = !d.show_detail">
                        <td>{{ dk + 1 }}</td>
                        <td v-html="d.ctime.replace(' ', '<br />')"></td>
                        <td>{{ d.company_name }}</td>
                        <td>{{ d.required_model }}</td>
                        <td>
                            <div>
                                <span class="badge bg-danger" v-if="d.flow_status == 'reject'"
                                    :title="d.flow_var['reply_status'] == 'supplementary_data' ? $store.state.language.agent.flow_reject_resend : $store.state.language.agent.flow_reject_full">
                                    <i class="fa fa-times"></i>
                                    {{ $store.state.language.agent.flow_reject }}
                                </span>
                                <span class="badge bg-warning" v-else-if="d.flow_status == 'send'"
                                    :title="$store.state.language.agent.flow_send_full">
                                    <i class="far fa-paper-plane"></i>
                                    {{ $store.state.language.agent.flow_send }}
                                </span>
                                <span class="badge bg-success" v-else-if="d.flow_status == 'pass' && !d.expire"
                                    :title="$store.state.language.agent.flow_pass_full">
                                    <i class="fa fa-check"></i>
                                    {{ $store.state.language.agent.flow_pass }}
                                </span>
                                <span class="badge bg-secondary" v-else-if="d.expire">
                                    <i class="far fa-hand-paper"></i>
                                    {{ $store.state.language.agent.flow_expire }}
                                </span>
                            </div>
                            <div>
                                <router-link class="btn btn-outline-primary btn-sm py-0"
                                    v-if="d.flow_status == 'reject' && d.flow_var['reply_status'] == 'supplementary_data'"
                                    :to="`/agent/customer_register/${d.id}`">
                                    <i class="fa fa-pen"></i>
                                    {{ $store.state.language.agent.flow_edit }}
                                </router-link>
                            </div>
                        </td>
                    </tr>
                    <transition name="fade">
                        <tr v-show="d.show_detail">
                            <td colspan="5">
                                <div class="row m-0">
                                    <div class="col-md-12" v-if="d.flow_status == 'reject'">
                                        <div style="background-color: #FED;padding: 5px 10px;font-size: 15px">
                                            {{ d.flow_var['reply_status'] == 'supplementary_data' ?
                                            $store.state.language.agent.flow_reject_resend :
                                            $store.state.language.agent.flow_reject_full }}
                                            <div v-if="d.last_flow && d.last_flow.reply">
                                                {{ d.last_flow.reply }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>{{ $store.state.language.agent.list_column.industry }}</strong>
                                        </small>
                                        <div style="color: #666;">{{ d.industry }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>{{ $store.state.language.agent.list_column.url }}</strong>
                                        </small>
                                        <div>
                                            <a target="_blank" :href="d.url">{{ d.url }}</a>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>
                                                {{ $store.state.language.agent.list_column.machine_install }}
                                            </strong>
                                        </small>
                                        <div style="color: #666;">{{ d.machine_install }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>
                                                {{ $store.state.language.agent.list_column.contact }}
                                            </strong>
                                        </small>
                                        <div style="color: #666;">{{ d.contact }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>
                                                {{ $store.state.language.agent.list_column.unit }}
                                            </strong>
                                        </small>
                                        <div style="color: #666;">{{ d.unit }}</div>
                                    </div>

                                    <div class="col-md-4">
                                        <small>
                                            <strong>
                                                {{ $store.state.language.agent.list_column.rank }}
                                            </strong>
                                        </small>
                                        <div style="color: #666;">{{ d.rank }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <small>
                                            <strong>
                                                {{ $store.state.language.agent.list_column.files }}
                                            </strong>
                                        </small>
                                        <div class="row">
                                            <div class="col-md-3" v-show="d.files.length==0">-</div>
                                            <div class="col-md-3" v-for="df in d.files" :key="df">
                                                <img :src="df" style="width: 100%;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </transition>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            dataList: []
        };
    },
    mounted() {
        let vue = this,
            today = moment().format("YYYY-MM-DD");
        vue.$store
            .dispatch("post_form", {
                payload: { url: "/api/index.php?act=list" }
            })
            .then(res => {
                vue.dataList = res.data.dataList.map(item => {
                    item.expire =
                        moment(item.finish_date)
                            .add(3, "month")
                            .format("YYYY-MM-DD") < today;
                    item.files = JSON.parse(item.files);
                    item.show_detail = false;
                    //
                    item.flow_data = item.flow_data != '' ? JSON.parse(item.flow_data) : [];
                    item.flow_var = item.flow_var != '' ? JSON.parse(item.flow_var) : [];
                    item.flow_extra = '';
                    item.last_flow = item.flow_data[item.flow_data.length - 1];
                    return item;
                });
            });
    }
};
</script>
